.App {
  position: relative;
  min-height: 100vh;
}

h2 {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 35px;
  font-weight: 200;
  margin-top: 30px;
  text-align: center;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 40px;
  margin-bottom: 2px;
  text-align: left;
  font-weight: 200;
  margin-left: 5.5%;
}

p {
  font-size: 15px;
}

.header-row {
  padding-top: 0px;
  padding-bottom: 30px;
}

.content {
  background: linear-gradient(rgb(215, 215, 215), white);
  background-repeat: no-repeat;
  padding-bottom: 50px;
}

a {
  color: inherit;
  text-decoration: none;
}

.news-img {
  width: 100%;
  height: auto;
}

.news-card {
  box-shadow: 5px 10px 25px rgba(0, 0, 0, 0.1);
  background-color: white;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  /* height: 0;
  padding-top: 100%; */
  margin-bottom: 20px;
  min-height: 500px;
  overflow: hidden;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}

.news-card:hover {
  transform: translate(0, -2px) scale(1.05);
  box-shadow: 5px 10px 25px rgba(0, 0, 0, 0.7);
}
