* {
    box-sizing: border-box;
  }
  
  .row {
    max-width: 1600px;
    padding-left: 2%;
    padding-right: 2%;
    margin: auto;
  }
  
  /* Clearfix*/
  .row::before, 
  .row::after {
    display: table;
    content: '';
  }
  
  .row::after {
    clear: both;
  }
  
  .col-3, .col-4, .col-4-b, .col-6, .col-8, .col-12 {
    float: left;
    padding-left: 1.04166666%;
    padding-right: 1.041666666%;
  }
  
  /*Mobile defaults */
  .col-3, .col-4, .col-4-b, .col-6, .col-8, .col-12 {
    width: 100%;
  }

  @media only screen and (min-width: 640px) {
    .col-4-b {
      width: 50%;
    }
  }
  
@media only screen and (min-width: 800px) {
    .col-3 {
      width: 25%;
    }
    .col-4, .col-4-b {
      width: 33.3%;
    }
    .col-6 {
      width: 50%;
    }
    .col-8 {
      width: 75%;
    }
    .col-12 {
      width: 100%
    }
}