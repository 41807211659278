.chart-card {
    box-shadow: 5px 10px 25px rgba(0, 0, 0, 0.5);
    background-color: white;
    width: 100%;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-bottom: 20px;
}

.list-card {
    box-shadow: 5px 10px 25px rgba(0, 0, 0, 0.5);
    background-color: white;
    width: 100%;
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    max-height: 530px;
    overflow-y: scroll;
}

.load-container {
    padding-top: 10px;
    padding-bottom: 10px;
}

.important-text {
    color: #a70101;
    font-weight: bold;
}

.data-details {
    margin-left: 14%;
}

.jvectormap-tip {
    position: absolute;
    display: none;
    border: solid 1px rgb(255, 255, 255);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: #000000;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 13px;
    padding: 5px;
    z-index:11;
 }